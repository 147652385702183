/** @jsxRuntime classic */
/** @jsx jsx */
import * as React from 'react'
import { jsx, useThemeUI } from 'theme-ui'
import { animated, useTransition, useSprings } from '@react-spring/web'

import styled from '@emotion/styled'

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`

const StyledNavItem = styled(animated.button)`
  border: 0;
  background: 0;
  padding: 0 8px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  outline: none;
`

const NavItem = (props) => <StyledNavItem {...{ sx: { fontFamily: 'heading' }, ...props }} />

const Section = styled.section``

const Article = styled.article`
  width: 100%;
  height: 560px;
  position: relative;
`

const Item = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  & * {
    pointer-events: none;
  }
`

type GalleryProps = {
  children: React.ReactElement[]
}

const Gallery = React.forwardRef<any, GalleryProps>(({ children }, ref) => {
  const [index, set] = React.useState(0)
  const childrenArray: React.ReactElement[] = React.Children.toArray(children) as any
  const count = childrenArray.length
  // const index = React.useRef(0)
  const transition = useTransition(index, {
    initial: { opacity: 1 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })
  return (
    <Section>
      {count > 1 && (
        <Nav>
          <NavItem
            {...{
              onClick: () => set((i) => (i > 0 ? --i : i)),
              disabled: index === 0
            }}
          >
            ← Prev
          </NavItem>
          <NavItem {...{ onClick: () => set((i) => (i < count - 1 ? ++i : 0)), disabled: index === count - 1 }}>
            Next →
          </NavItem>
        </Nav>
      )}
      <Article>
        {transition((style, index) => {
          const child = childrenArray[index]
          return (
            <Item {...{ style: style as any }}>
              <child.type
                {...{
                  ...child.props,
                  style: { width: '100%' },
                  objectFit: 'contain',
                  objectPosition: 'top center'
                }}
              />
            </Item>
          )
        })}
      </Article>
    </Section>
  )
})

export { Gallery }
